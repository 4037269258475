/* react quill start */

.ql-editor {
	font-family: var(--quill-editor-default-font-family);
}

/* react quill end */

.template-box {
	border: 1px solid #ddd;
	padding: 20px;
	border-radius: 5px;
	background: #f8f9fa;
	text-align: center;
}
.btn-custom {
	display: block;
	margin-top: 10px;
}
.table th, .table td {
	vertical-align: middle;
}